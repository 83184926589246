<template>
  <b-container>
    <div v-if="fullName">
      <b-row><b-col><h1 class="text-uppercase">{{ fullName }}</h1></b-col></b-row>
    </div>
    <div v-if="autorizado === true">
      <b-row>
        <b-col lg="7" class="cardGramos p-3">
          <b-row class="p-3"><b-col><b-img :src="require('../assets/gramos-totales.png')"></b-img><span class="title">Progreso</span></b-col></b-row>
          <b-row>
            <b-col class="graph-container"><vue-ellipse-progress :progress="progreso" :size=200 fontSize="3rem" :color="progresoColor" thickness="10%"  /></b-col>
          </b-row>
          <b-row>
            <b-col offset="3" lg="3">
              <span class="weightRecord">{{ initialWeight}}</span> kg<br /><span class="pesoInicial">Peso inicial</span>
            </b-col>
            <b-col offset="2" lg="4">
              <span class="weightRecord">{{ targetWeight}}</span> kg<br /><span class="pesoSaludable">Peso saludable</span>
            </b-col>
          </b-row>

        </b-col>
        <b-col lg="5" class="cardGramos p-3">
          <b-row class="p-3"><b-col><b-img :src="require('../assets/gramos-totales.png')"></b-img><span class="title">Evolución</span></b-col></b-row>
          <b-row>
            <b-col lg="6" class="pt-3 pb-3">

              <b-row>
                <b-col>
                  <div class="imagenEvolucion" style="float:right;">
                    <b-row>
                      <b-col>
                        <span class="weightRecord">{{ initialWeight}}</span> kg
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <img v-if="imagenInicial !=''" :src="urlGRAMOSAPI+imagenInicial.image_thumb" />
                        <span class="imagenEvolucionTexto pesoInicial">Peso inicial</span>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>


            </b-col>
            <b-col lg="6" class="pt-3 pb-3">
              <b-row>
                <b-col>
                  <div class="imagenEvolucion">
                    <b-row>
                      <b-col>
                        <span class="weightRecord">{{ weight }}</span> kg
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <img v-if="imagenActual !=''" :src="urlGRAMOSAPI+imagenActual.image_thumb" />
                        <span class="imagenEvolucionTexto pesoSaludable">Peso actual</span>
                      </b-col>
                    </b-row>

                  </div>
                </b-col>
              </b-row>

            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4" class="cardGramos p-3">
          <b-row class="p-3"><b-col><b-img :src="require('../assets/gramos-totales.png')"></b-img><span class="title">Peso</span></b-col></b-row>
          <b-row class="p-3">
            <b-col lg="4">
              <b-row>
                <b-col><span>Último registro</span></b-col>
              </b-row>
              <b-row>
                <b-col><span class="pesoData">{{ weight.toString().replace('.', ',') }}</span> kg</b-col>
              </b-row>

            </b-col>
            <b-col lg="4">
              <b-row><b-col>Últimos 7 días</b-col></b-row>
              <b-row>
                <b-col lg="3">
                  <div v-if="weightLastWeek > 0">
                    <b-icon class="iconPesoData" icon="arrow-down-circle-fill" variant="verdeGramos"></b-icon>
                  </div>
                  <div v-else-if="weightLastWeek < 0">
                    <b-icon class="iconPesoData" icon="arrow-up-circle-fill" variant="grisGramos"></b-icon>
                  </div>
                  <div v-else-if="weightLastWeek = 0">
                    <b-icon class="iconPesoData" icon="arrow-right-circle-fill" variant="naranjaGramos"></b-icon>
                  </div>
                </b-col>
                <b-col lg="9">
                  <div v-if="weightLastWeek">
                    <span class="pesoData">{{ weightLastWeek.toString().replace('.', ',') }}</span> kg
                  </div>
                  <div v-else>
                    <span class="pesoData">-</span>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="4">
              <b-row><b-col><span class="pesoSaludable">Acumulado</span></b-col></b-row>
              <b-row class="mt-2">
                <b-col lg="3">
                  <div v-if="weightAggregate > 0">
                    <b-icon class="iconPesoData" icon="arrow-down-circle-fill" variant="verdeGramos"></b-icon>
                  </div>
                  <div v-else-if="weightAggregate < 0">
                    <b-icon class="iconPesoData" icon="arrow-down-circle-fill" variant="grisGramos"></b-icon>
                  </div>
                  <div v-else-if="weightAggregate = 0">
                    <b-icon class="iconPesoData" icon="arrow-down-circle-fill" variant="naranjaGramos"></b-icon>
                  </div>
                </b-col>
                <b-col lg="9">
                  <div v-if="weightAggregate">
                    <span class="pesoData">{{ weightAggregate.toString().replace('.', ',') }}</span> kg
                  </div>
                  <div v-else>
                    <span class="pesoData">-</span>
                  </div>
                </b-col>
              </b-row>
            </b-col>

          </b-row>
          <b-row class="p-3">
<!--            <b-col lg="3"><b>Evolución de peso acumulada</b></b-col>-->
            <b-col lg="12">
              <mdb-container>
                <mdb-line-chart
                    :data="evolutionWeightChartData"
                    :options="evolutionWeightChartOptions"
                    :width="260"
                    :height="130"
                ></mdb-line-chart>
              </mdb-container>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="4" class="cardGramos p-3">
          <b-row class="p-3">
            <b-col><b-img :src="require('../assets/comidas-sanas.png')"></b-img><span class="title">Comidas sanas</span></b-col>
          </b-row>
          <b-tabs content-class="mt-3" class="tabAcumuladoGramos" justified>
            <b-tab title="Semanal" active>
              <b-row class="justify-content-center p-3">
                <b-col lg="3">
                  <b-row :class="progresoComidasTextColor">
                    <b-col>
                      <h3>{{ foodPercent }}%</h3>
                    </b-col>
                  </b-row>
                  <b-row :class="progresoComidasTextColor">
                    <b-col>de tus comidas fueron <b>sanas</b></b-col>
                  </b-row>
                </b-col>
                <b-col lg="9">
                  <mdb-container>
                    <mdb-bar-chart
                        :data="comidasSanasChartData"
                        :options="comidasSanasChartOptions"
                        :width="260"
                        :height="130"
                    ></mdb-bar-chart>
                  </mdb-container>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Acumulado">
              <b-row class="justify-content-center p-3">
                <b-col lg="3">
                  <b-row :class="progresoComidasTextColor">
                    <b-col>
                      <h3>{{ foodPercent }}%</h3>
                    </b-col>
                  </b-row>
                  <b-row :class="progresoComidasTextColor">
                    <b-col>de tus comidas fueron <b>sanas</b></b-col>
                  </b-row>
                </b-col>
                <b-col lg="9">
                  <mdb-container>
                    <mdb-bar-chart
                        :data="comidasSanasAcumuladoChartData"
                        :options="comidasSanasAcumuladoChartOptions"
                        :width="260"
                        :height="130"
                    ></mdb-bar-chart>
                  </mdb-container>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
          <b-row class="p-3">
            <b-col lg="6">
              <b-row>
                <b-col>
                  <b>Racha actual</b>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-center">
                  <span class="rachaNro">{{ foodCurrentPeriod }}</span> días
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="6">
              <b-row>
                <b-col>
                  <b>Mejor racha</b>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-center">
                  <span class="rachaNro">{{ foodBestPeriod }}</span> días
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="p-3">
            <b-col lg="6" :class="progresoComidasTextColor">
              <b-row>
                <b-col cols="auto" class="mr-auto"><h3>{{ foodDays }}</h3></b-col>
                <b-col>días comiendo sano</b-col>
              </b-row>
            </b-col>

            <b-col lg="6">
              <b-row>
                <b-col cols="auto" class="mr-auto"><h3>{{timeInGramos}}</h3></b-col>
                <b-col>días en el programa</b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-progress :value="progresoComidas" :variant="progresoComidasColor" ></b-progress>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="4" class="cardGramos p-3">
          <b-row class="p-3">
            <b-col><b-img :src="require('../assets/ejercicio.png')"></b-img><span class="title">Ejercicio</span></b-col>
          </b-row>
          <b-tabs content-class="mt-3" class="tabAcumuladoGramos" justified>
            <b-tab title="Semanal" active>
              <b-row class="justify-content-center p-3">
<!--                <b-col lg="3"><b>Minutos de caminata</b></b-col>-->
                <b-col lg="12">
                  <mdb-container>
                    <mdb-line-chart
                        :data="ejercicioChartData"
                        :options="ejercicioChartOptions"
                        :width="260"
                        :height="130"
                    ></mdb-line-chart>
                  </mdb-container>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Acumulado">
              <b-row class="justify-content-center p-3">
<!--                <b-col lg="3"><b>Minutos de caminata acumulado</b></b-col>-->
                <b-col lg="12">
                  <mdb-container>
                    <mdb-line-chart
                        :data="ejercicioAcumuladoChartData"
                        :options="ejercicioAcumuladoChartOptions"
                        :width="260"
                        :height="130"
                    ></mdb-line-chart>
                  </mdb-container>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
<!--          <b-row>-->
<!--            <b-col lg="3"><b>Minutos de caminata</b></b-col>-->
<!--            <b-col lg="9">-->
<!--              <mdb-container>-->
<!--                <mdb-line-chart-->
<!--                    :data="ejercicioChartData"-->
<!--                    :options="ejercicioChartOptions"-->
<!--                    :width="260"-->
<!--                    :height="130"-->
<!--                ></mdb-line-chart>-->
<!--              </mdb-container>-->
<!--            </b-col>-->
<!--          </b-row>-->
<!--          <b-row>-->
<!--            <b-col lg="3"><b>Minutos de caminata acumulado</b></b-col>-->
<!--            <b-col lg="9">-->
<!--              <mdb-container>-->
<!--                <mdb-line-chart-->
<!--                    :data="ejercicioAcumuladoChartData"-->
<!--                    :options="ejercicioAcumuladoChartOptions"-->
<!--                    :width="260"-->
<!--                    :height="130"-->
<!--                ></mdb-line-chart>-->
<!--              </mdb-container>-->
<!--            </b-col>-->
<!--          </b-row>-->
          <b-row class="p-3">
            <b-col lg="6">
              <b-row>
                <b-col>
                  <b>Racha actual</b>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-center">
                  <span class="rachaNro">{{ this.sportsCurrentPeriod }}</span> días
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="6">
              <b-row>
                <b-col>
                  <b>Mejor racha</b>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-center">
                  <span class="rachaNro">{{ this.sportsBestPeriod }}</span> días
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="p-3">
            <b-col lg="6" :class="progresoEjercicioTextColor">
              <b-row>
                <b-col cols="auto" class="mr-auto"><h3>{{this.sportsDays}}</h3></b-col>
                <b-col>
                  días haciendo ejercicio
                </b-col>
              </b-row>
            </b-col>

            <b-col lg="6">
              <b-row>
                <b-col cols="auto" class="mr-auto"><h3>{{timeInGramos}}</h3></b-col>
                <b-col>
                  días en el programa
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-progress :value="progresoEjercicio" :variant="progresoEjercicioColor" ></b-progress>
            </b-col>
          </b-row>
        </b-col>

      </b-row>
    </div>
    <div v-else-if="autorizado === false">
      <b-row><b-col><p><strong>Acceso no autorizado. Debe <a v-bind:href="'/'">iniciar sesión</a>. </strong></p></b-col></b-row>
    </div>

  </b-container>
</template>

<script>
import axios from "axios";
import auth from "../logic/auth";
import { mdbBarChart, mdbLineChart, mdbContainer } from "mdbvue";
import moment from "moment"
import gramosStyles from "../assets/scss/gramos.scss"

export default {
  components: {
    mdbBarChart,
    mdbLineChart,
    mdbContainer,
  },
  data() {
    return {
      naranjaGramos: gramosStyles.naranjaGramos,
      amarilloGramos: gramosStyles.amarilloGramos,
      grisGramos: gramosStyles.grisGramos,
      verdeGramos: gramosStyles.verdeGramos,

      evolutionWeightChartData: {},
      evolutionWeightChartOptions: {},



      autorizado: null,
      fullName:'',
      progreso: 0,
      progresoColor: '',
      comidasColor: '',
      progresoComidas: 0,
      progresoComidasColor: '',
      progresoComidasTextColor: '',
      progresoEjercicio: 0,
      progresoEjercicioColor: '',
      progresoEjercicioTextColor: '',
      initialWeight:0,
      targetWeight:0,
      weight: 0,
      weightLastWeek: 0,
      weightAggregate: 0,
      timeInGramos: 0,
      foodCurrentPeriod:0,
      foodBestPeriod:0,
      foodDays: 0,
      foodPercent: 0,
      sportsCurrentPeriod: 0,
      sportsBestPeriod: 0,
      sportsDays: 0,
      ejercicioChartData: {},
      ejercicioChartOptions: {},
      ejercicioAcumuladoChartData: {},
      ejercicioAcumuladoChartOptions: {},
      comidasSanasChartData: {},
      comidasSanasChartOptions: {},
      comidasSanasAcumuladoChartData: {},
      comidasSanasAcumuladoChartOptions: {},
      imagenInicial: {},
      imagenActual: {},
      urlGRAMOSAPI:'',

    }
  },
  methods:{
  },
  mounted() {
    this.accessToken = auth.getUserLogged('accessToken')
    this.urlGRAMOSAPI = process.env.VUE_APP_GRAMOSAPIHOST
    if (this.accessToken) {
      axios
          .get(process.env.VUE_APP_GRAMOSAPIHOST+'admin/data_usuario?user_id='+this.$route.params.user_id, {
            headers: {
              'Authorization': 'Bearer ' + this.accessToken
            }
          })
          .then(response => {
            this.autorizado = true
            this.fullName = response.data.fullName
          })
          .catch(err => {
            this.autorizado = false
            console.log(err);
          })

      axios
          .get(process.env.VUE_APP_GRAMOSAPIHOST+'admin/usuario?user_id='+this.$route.params.user_id, {
            headers: {
              'Authorization': 'Bearer ' + this.accessToken
            }
          })
          .then(response =>{
            this.autorizado = true
            console.log(response)
            this.progreso = response.data.score
            this.progresoColor = response.data.score>90?this.verdeGramos:(response.data.score>80?this.amarilloGramos:(response.data.score>70?this.naranjaGramos:this.grisGramos))
            this.initialWeight = response.data.initialWeight
            this.targetWeight = response.data.targetWeight
            this.weight = response.data.weight
            this.weightLastWeek = response.data.historicWeight.lastWeek
            this.weightAggregate = response.data.historicWeight.aggregate
            this.timeInGramos = response.data.totalDays
            this.foodBestPeriod = response.data.food.bestPeriod
            this.foodCurrentPeriod = response.data.food.currentPeriod
            this.foodDays = response.data.food.totalFoodDays
            this.foodPercent = response.data.food.percent
            this.progresoComidas = this.foodDays*100/this.timeInGramos
            this.progresoComidasColor = this.progresoComidas>90?'verdeGramos':(this.progresoComidas>80?'amarilloGramos':(this.progresoComidas>70?'naranjaGramos':'grisGramos'))
            this.progresoComidasTextColor = 'text-'+this.progresoComidasColor
            this.sportsBestPeriod = response.data.sports.bestPeriod
            this.sportsCurrentPeriod = response.data.sports.currentPeriod
            this.sportsDays = response.data.sports.totalSportsDays
            this.progresoEjercicio = this.sportsDays*100/this.timeInGramos
            this.progresoEjercicioColor = this.progresoEjercicio>90?'verdeGramos':(this.progresoEjercicio>80?'amarilloGramos':(this.progresoEjercicio>70?'naranjaGramos':'grisGramos'))
            this.progresoEjercicioTextColor = 'text-'+this.progresoEjercicioColor
            this.imagenInicial = typeof(response.data.images[0])!='undefined'?response.data.images[0]:''
            this.imagenActual = typeof(response.data.images[1])!='undefined'?response.data.images[1]:''

            this.evolutionWeightChartData = {
              labels: [],
              datasets: [
                {
                  label: "Evolución de peso acumulada",
                  backgroundColor: "rgba(255, 99, 132, 0.1)",
                  borderColor: "rgba(255, 99, 132, 1)",
                  borderWidth: 0.7,
                  data: []
                }
              ]
            }

            // for (let i=0; i< response.data.historicWeight.evolution.length; i++){
            //   this.evolutionWeightChartData.labels.push(i+1)
            //   this.evolutionWeightChartData.datasets[0].data.push(response.data.historicWeight.evolution[i].value)
            // }
            for (let i=0; i<response.data.weightAccumulated.values.length; i++){
              // console.log(response.data.sports.days[i])
              this.evolutionWeightChartData.labels.push(moment(response.data.weightAccumulated.values[i].day).format('DD-MM-YYYY'))
              this.evolutionWeightChartData.datasets[0].data.push(response.data.weightAccumulated.values[i].value)
            }

            this.evolutionWeightChartOptions = {
              responsive: false,
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      display: true,
                      color: "rgba(0, 0, 0, 0.1)"
                    }
                  }
                ],
                yAxes: [
                  {
                    gridLines: {
                      display: true,
                      color: "rgba(0, 0, 0, 0.1)"
                    }
                  }
                ]
              }
            }

            this.ejercicioChartData={
              labels: [
                // "Red",
                // "Blue",
                // "Yellow",
                // "Green",
                // "Purple",
                // "Orange"
              ],
              datasets: [
                {
                  label: "Minutos de caminata",
                  data: [/*12, 19, 3, 5, 2, 3*/],
                  backgroundColor: [
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(255, 206, 86, 0.2)",
                    "rgba(75, 192, 192, 0.2)",
                    "rgba(153, 102, 255, 0.2)",
                    "rgba(255, 159, 64, 0.2)"
                  ],
                  borderColor: [
                    "rgba(255,99,132,1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)"
                  ],
                  borderWidth: 1
                }
              ],
            }

            for (let i=0; i<response.data.sports.days.length; i++){
              console.log(response.data.sports.days[i])
              this.ejercicioChartData.labels.push(moment(response.data.sports.days[i].day).format('dd'))
              this.ejercicioChartData.datasets[0].data.push(response.data.sports.days[i].value)
            }

            this.ejercicioChartOptions = {
              responsive: false,
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    barPercentage: 1,
                    gridLines: {
                      display: true,
                      color: "rgba(0, 0, 0, 0.1)"
                    }
                  }
                ],
                yAxes: [
                  {
                    gridLines: {
                      display: true,
                      color: "rgba(0, 0, 0, 0.1)"
                    }
                  }
                ]
              },
            }


            this.ejercicioAcumuladoChartData={
              labels: [
                // "Red",
                // "Blue",
                // "Yellow",
                // "Green",
                // "Purple",
                // "Orange"
              ],
              datasets: [
                {
                  label: "Minutos de caminata acumulado",
                  data: [/*12, 19, 3, 5, 2, 3*/],
                  backgroundColor: [
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(255, 206, 86, 0.2)",
                    "rgba(75, 192, 192, 0.2)",
                    "rgba(153, 102, 255, 0.2)",
                    "rgba(255, 159, 64, 0.2)"
                  ],
                  borderColor: [
                    "rgba(255,99,132,1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)"
                  ],
                  borderWidth: 1
                }
              ],
            }

            for (let i=0; i<response.data.sports.accumulated.values.length; i++){
              console.log(response.data.sports.days[i])
              this.ejercicioAcumuladoChartData.labels.push(moment(response.data.sports.accumulated.values[i].day).format('DD-MM-YYYY'))
              this.ejercicioAcumuladoChartData.datasets[0].data.push(response.data.sports.accumulated.values[i].value)
            }

            this.ejercicioAcumuladoChartOptions = {
              responsive: false,
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    barPercentage: 1,
                    gridLines: {
                      display: true,
                      color: "rgba(0, 0, 0, 0.1)"
                    }
                  }
                ],
                yAxes: [
                  {
                    gridLines: {
                      display: true,
                      color: "rgba(0, 0, 0, 0.1)"
                    }
                  }
                ]
              },
            }





            this.comidasSanasChartData={
              labels: [
                // "Red",
                // "Blue",
                // "Yellow",
                // "Green",
                // "Purple",
                // "Orange"
              ],
              datasets: [
                {
                  label: "Comidas sanas",
                  data: [/*12, 19, 3, 5, 2, 3*/],
                  backgroundColor: [
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(255, 206, 86, 0.2)",
                    "rgba(75, 192, 192, 0.2)",
                    "rgba(153, 102, 255, 0.2)",
                    "rgba(255, 159, 64, 0.2)"
                  ],
                  borderColor: [
                    "rgba(255,99,132,1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)"
                  ],
                  borderWidth: 1
                }
              ],
            }

            for (let i=0; i<response.data.food.days.length; i++){
              this.comidasSanasChartData.labels.push(moment(response.data.food.days[i].day).format('dd'))
              this.comidasSanasChartData.datasets[0].data.push(response.data.food.days[i].value)
            }

            this.comidasSanasChartOptions = {
              responsive: false,
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    barPercentage: 1,
                    gridLines: {
                      display: true,
                      color: "rgba(0, 0, 0, 0.1)"
                    }
                  }
                ],
                yAxes: [
                  {
                    gridLines: {
                      display: true,
                      color: "rgba(0, 0, 0, 0.1)"
                    }
                  }
                ]
              },
            }


            this.comidasSanasAcumuladoChartData={
              labels: [
                // "Red",
                // "Blue",
                // "Yellow",
                // "Green",
                // "Purple",
                // "Orange"
              ],
              datasets: [
                {
                  label: "Comidas sanas acumulado",
                  data: [/*12, 19, 3, 5, 2, 3*/],
                  backgroundColor: [
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(255, 206, 86, 0.2)",
                    "rgba(75, 192, 192, 0.2)",
                    "rgba(153, 102, 255, 0.2)",
                    "rgba(255, 159, 64, 0.2)"
                  ],
                  borderColor: [
                    "rgba(255,99,132,1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)"
                  ],
                  borderWidth: 1
                }
              ],
            }

            for (let i=0; i<response.data.food.accumulated.values.length; i++){
              console.log(response.data.sports.days[i])
              this.comidasSanasAcumuladoChartData.labels.push(moment(response.data.food.accumulated.values[i].day).format('DD-MM-YYYY'))
              this.comidasSanasAcumuladoChartData.datasets[0].data.push(response.data.food.accumulated.values[i].value)
            }

            this.ejercicioAcumuladoChartOptions = {
              responsive: false,
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    barPercentage: 1,
                    gridLines: {
                      display: true,
                      color: "rgba(0, 0, 0, 0.1)"
                    }
                  }
                ],
                yAxes: [
                  {
                    gridLines: {
                      display: true,
                      color: "rgba(0, 0, 0, 0.1)"
                    }
                  }
                ]
              },
            }



          })
          .catch(err => {
            this.autorizado = false
            auth.deleteUserLogged()
            console.log(err);
          })
    }
    else{
      this.autorizado = false
    }

  }
};

</script>
